.AppContainter{
    display: flex;
    flex-direction: column;
    width: 101%;
    margin-left: -1%;
    margin-top: -1.5vh;
    height: 99.8vh;
}

.PagesContainers{
    height: 100%;
    width: 100%;
    display: flex;
    font-family:  'Inter';
}

button {
    font-family: 'Inter', sans-serif;
}

input {
    font-family: 'Inter', sans-serif;
}

label {
    font-family: 'Inter', sans-serif;
}

h1 {
    font-family: 'Inter', sans-serif;
    color: #0045aa
}

h5 {
    font-family: 'Inter', sans-serif;
    color: #0045aa
}

/* We start defining css of the LoadingInfo file */

.LoadingInfo{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BottomLine {
    border-bottom: solid 2px #009FFF;
}

.SuperiorLine {
	border-top: solid 2px #009FFF;
}


/* We start defining css of the LEFT AND RIGHT SICE file */

.PageMainDiv {
    width: 100%;
    height: 99.6%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PageRightSide{
    width: max(calc(100% - 300px), 80%);
    margin-right: -0.5%;
    overflow-y: auto;
    min-height: 102%;
    max-height: 102%;
    height: auto;
    box-sizing: border-box;
}

.PageLeftSide {
    max-width: 300px;
    width: 20%;
    margin-top: -0.5%;
    height: 103.2%;
    display: flex;
    flex-direction: column;
    border-right: solid 2px #009FFF;
    box-sizing: border-box;
}


.PageLogo{
    cursor: pointer;
    width: 100%;
    height: 20%;
    background: linear-gradient(180deg, #00E0FF 0%, #156FFF 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.PageLogo img{
    width: 70%;
    height: 100%;
}

.PageModalSelector{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    align-items: center;
    width: 90%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.PageModalSelectorAdmin{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.FullPageSelector {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.FullSubPage {
    width: 90%;
    margin-left: 6%;
}

.PageAccountSettings{
    height: 15%;
    width: 100%;
    border-top: solid 2px #009FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PageAccountSettingsInternal{
    width: 90%;
    margin-left: 15%;
}

.ModalContainerMainDiv{
    width: 100%;
    min-height: 101.4vh;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #f0f7fd;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
}

.ConteinerModalShower {
    width: 99.8%;
    min-height: 91.5vh;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ModalTitleDiv {
    width: 95%;
    height: 9%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
}

.ModalDisplayer {
    width: 95%;
    min-height: 60vh;
    background-color: white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
}

.ModalDisplayer h2 {
    color: #0045AA;
}

.BiggerModalDisplayer {
    min-height: 80vh !important;
    background-color: transparent !important;
}