/* SharedCSS */

.BottomLine {
	border-bottom: solid 2px #009FFF;
}

.SuperiorLine {
	border-top: solid 2px #009FFF;
}

/* CSS For PopUps */

.confirm-popup {
	width: 80%;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 20px;
	border: solid 2px #009FFF;
	border-radius: 10px;
	z-index: 1000;
	font-size: large;
}

.confirm-popup input {
	width: calc(100% - 18px);
	padding: 8px;
	margin: 10px 0;
	font-size: large;
}

.confirm-popup-mid-div {
	display: flex;
	justify-content: space-evenly;
}

.confirm-popup-mid-div button {
	min-width: 22%;
	max-width: 22%;
	padding: 0.5%;
	margin: 1%;
	border-radius: 25px;
	cursor: pointer;
	font-size: large;
	font-weight: bold;
	border: solid 2px transparent;
	background-color: #009FFF;
	color: white;
}

.confirm-popup-mid-div button:hover {
	cursor: pointer;
	font-size: large;
	font-weight: bold;
	border: solid 2px #009FFF;
	background-color: transparent;
	color: #009FFF;
}

/* CSS For SchoolTopSummary */

.schoolTopSummaryMainDiv {
	margin-top: 2%;
	background-color: white;
	width: 95%;
	border-radius: 25px;
	height: 15%;
	max-height: 15vh;
	display: flex;
}

.schoolTopSummaryImgDiv {
	width: 25%;

	display: flex;
	justify-content: center;
	align-items: center;
}

.schoolTopSummaryImgDiv img {
	height: 75%;
	aspect-ratio: 1/1;
}

.schoolTopSummaryInfoDiv {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #156FFF;
	overflow-x: hidden;

}

.schoolTopSummaryInfoDiv h2,
.schoolTopSummaryInfoDiv h3 {
	width: 100%;
	margin: 5px;
}

.schoolTopSummaryInfoDiv h2 {
	font-size: large;
	font-weight: bold;
	color: #0045aa;
}

.schoolTopSummaryInfoDiv h3 {
	font-size: small;
	font-weight: lighter;
}


/* CSS For GroupHomeNewsLetters */

.GroupHomeUnResolvedNotices {
	width: 90%;
	height: 25%;
}

.GroupHomeNoticesTitles {
	width: 100%;
	height: 25%;
	max-height: 46px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.GroupHomeNoticesTitles h4 {
	font-weight: lighter;
	font-size: medium;
	color: #009FFF;
}

.GroupHomeNoticesTitles button {
	margin-right: 2%;
	background-color: transparent;
	border: solid 2px transparent;
	cursor: pointer;
	color: #009FFF;
	font-size: medium;
	font-family: 'Inter', sans-serif;
	text-decoration: underline;
}

.GroupHomeUnResolvedNoticeData {
	min-height: 9vh;
	height: 75%;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.GroupHomeResolvedNotices {
	width: 90%;
	margin-bottom: 2.5%;
}

.GroupHomeResolvedNotices h4 {
	font-weight: lighter;
	font-size: medium;
	color: #009FFF;
}

.GroupHomeResolvedNoticeData {
	height: 75%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: #1D2B44 !important;
}

.GroupHomeResolvedNoticeInternalData1 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1%;
}

.GroupHomeResolvedNoticeInternalData2 {
	display: flex;
	justify-content: space-between;
}

/* CSS For GroupHomeNewsLetters */

.GroupHomeTodayNewsLetters {
	width: 90%;
	height: 30%;
}

.GroupHomeNewsLettersTitles {
	width: 100%;
	height: 25%;
	max-height: 46px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.GroupHomeNewsLettersTitles h4 {
	font-weight: lighter;
	font-size: medium;
	color: #009FFF;
}

.GroupHomeNewsLettersTitles button {
	margin-right: 2%;
	background-color: transparent;
	border: solid 2px transparent;
	cursor: pointer;
	color: #009FFF;
	font-size: medium;
	font-family: 'Inter', sans-serif;
	text-decoration: underline;
}

.GroupHomeTodayNewsLettersData {
	height: 75%;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.GroupHomeThisWeekNewsLetters {
	width: 90%;
	margin-bottom: 2.5%;
}

.GroupHomeTodayNewsLetters h4 {
	font-weight: lighter;
	font-size: medium;
	color: #009FFF;
}

.GroupHomeThisWeekNewsLettersData {
	height: 75%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.GroupHomeThisWeekNewsLettersInternalData1 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1%;
}

.GroupHomeThisWeekNewsLettersInternalData2 {
	display: flex;
	justify-content: space-between;
}

/* CSS For ViewSingularNotification */

.ViewSingularNotificationMainDiv {
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #F0F7FD;
	cursor: pointer;
}

.ViewSingularNotificationMainDiv h1 {
	font-size: x-large;
	font-weight: bolder;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #1D2B44;
}

.ViewSingularNotificationMainDiv strong {
	font-size: 14px !important;
	padding: 8px;
	border-radius: 25px;
}

.ViewSingularNotificationMainDiv h4 {
	font-size: 14px !important;
	color: #1D2B44 !important;
}

.ViewSingularNotificationInternalDiv {
	width: 95%;
	height: 95%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.NoticeDescriptionDiv {
	height: 50%;
}

.NoticeDataDiv {
	height: 50%;
	display: flex;
	justify-content: space-between;
}

.NoticeDataLeftDiv {
	width: 55%;
	display: flex;
	align-items: center;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow-y: hidden;
}

.NoticeDataRightDiv {
	width: 40%;
	overflow-x: hidden;
	overflow-y: hidden;
	display: flex;
	align-items: center;
	justify-content: end;
	text-align: right !important;
}


/* We define the sizes of the notifications */

.SmallerNotification {
	width: 49%;
	min-height: min(8vh, 8%);
	max-height: max(10vh, 10%);
}


.BiggerNotification {
	width: 100%;
	margin-top: 8px;
	margin-bottom: 8px;
	min-height: min(15vh, 15%);
	max-height: max(15vh, 15%);
}

/* We define the css for GroupHistoryNotices */

.AlertGroupDiv {
	width: 100%;
}

.AlertGroupDiv h2 {
	color: #009FFF;
}

.AlertGroupDiv h4 {
	font-weight: lighter;
	font-size: medium;
	color: #009FFF;
}


/* We define the css for FilterAlerts */

.MainFilterAlertsDiv {
	margin-top: 2%;
	margin-bottom: 2%;
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #F0F7FD;
	border-radius: 50px;
}

.SubMainFilterAlertsDiv {
	width: 90%;
	margin: 2%;
}

.BasicDiv {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 2.5%;
}

.BasicDiv button {
	min-width: 22%;
	max-width: 22%;
	padding: 0.5%;
	margin: 1%;
	height: auto;
	border-radius: 25px;
	cursor: pointer;
	font-size: small;
	font-weight: normal;
	border: solid 2px #009FFF;
	background-color: #F0F7FD;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	box-sizing: border-box;
	overflow: hidden;
}

.StateButton button {
	padding: 8px !important;
}


.UnSelectedFilterButton {
	min-width: 22%;
	max-width: 22%;
	padding: 0.5%;
	margin: 1%;
	border-radius: 25px;
	cursor: pointer;
	font-weight: bold;
	border: solid 2px #009FFF;
	background-color: #F0F7FD;
}

.SelectedFilterButton {
	min-width: 22%;
	max-width: 22%;
	padding: 0.5%;
	margin: 1%;
	border-radius: 25px;
	cursor: pointer;
	font-weight: bold;
	border: solid 2px black !important;
}

.SelectedNormalColors {
	background-color: #009FFF !important;
	color: white;
	font-weight: bold;
	border: solid 2px #009FFF !important;
}

.FilterFinalButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 2.5%;
}

.FilterFinalButton button {
	min-width: 22%;
	max-width: 22%;
	padding: 0.5%;
	margin: 1%;
	border-radius: 25px;
	cursor: pointer;
	font-size: large;
	font-weight: bold;
	border: solid 2px transparent;
	background-color: #009FFF;
	color: white;
}

.FilterFinalButton button:hover {
	cursor: pointer;
	font-size: large;
	font-weight: bold;
	border: solid 2px #009FFF;
	background-color: transparent;
	color: #009FFF;
}

.FilterInferiorLine {
	border-bottom: solid 2px #009FFF;
}

.FilterMultipleRowObjects {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.FilterHalfWidth {
	width: 49%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.FilterHalfWidth h3 {
	height: 30%;
}

.BasicHalfDiv {
	height: 70%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 2.5%;
}

.BasicHalfDiv button {
	min-width: 22%;
	max-width: 22%;
	padding: 0.5%;
	margin: 1%;
	height: auto;
	border-radius: 25px;
	cursor: pointer;
	font-size: small;
	font-weight: normal;
	border: solid 2px #009FFF;
	background-color: #F0F7FD;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	box-sizing: border-box;
	overflow: hidden;
}


.LabelDiv {
	align-items: baseline !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.LabelDiv label {
	font-weight: bold;
	width: 45%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
}

.LabelDiv input {
	margin-left: 10px;
	border: solid 2px #009FFF;
	border-radius: 50px;
	max-height: 5vh;
	padding: 8px;
}

.LabelDiv h4 {
	width: 45%;
}

/* We define the css for ViewNoticeMainData */

.NoticeOwnersData {
	width: 90%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.NoticeOwnersData h4 {
	font-weight: lighter;
	font-size: medium;
	color: #009FFF;
}

.NoticeMainData {
	margin-top: 1%;
	width: 90%;
	border-radius: 20px;
	margin-bottom: 1%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.NoticeInternalData {
	margin-top: 1%;
	margin-bottom: 1%;
	width: 95%;
	height: 90%;
}

.NoticeMainData h1 {
	color: black;
}

.OptionButtonDiv {
	width: 90%;
	display: flex;
	flex-direction: row;
	margin-bottom: 1%;
}

.OptionButtonLeftDiv {
	width: 60%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	margin-bottom: 1%;
}

.OptionButtonLeftDiv button {
	width: 30%;
	padding: 5px;
	border-radius: 25px;
	background-color: white;
	cursor: pointer;
	font-size: large;
	font-weight: bold;
}


.FastCommentDiv {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.BasicFastComment {
	width: 20%;
	border-radius: 50px;
	text-align: center;
	padding: 8px;
	margin: 1%;
	font-family: 'Inter', sans-serif;
	font-size: medium;
	font-weight: bolder;
}

.FastPositiveComment {
	border: solid 2px red;
}

.FastNegativeComment {
	border: solid 2px gray;
}


.OptionButtonRightDiv {
	width: 40%;
	display: flex;
	justify-content: end;
	margin-bottom: 1%;
}

.OptionButtonRightDiv button {
	width: 40%;
	padding: 5px;
	border-radius: 25px;
	background-color: #08BC59;
	border: solid 2px #08BC59;
	cursor: pointer;
	font-weight: bold;
	font-size: large;
	color: white;
}

.OptionButtonRightDiv button:hover {
	color: #08BC59;
	background-color: white;
}

/* We define the sizes of the ViewCommentsOfNotice */

.CommentShowMainDiv {
	width: 80%;
	min-height: 40vh;
	overflow-y: auto;
	margin-bottom: 1%;
}

.CommentShowSubMainDiv {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: end;
}

.IndividualCommentOwnerMainDiv {
	width: 99.5%;
	display: flex;
	flex-direction: row-reverse;
	text-align: end;
	margin-bottom: 2%;
}

.IndividualCommentMainDiv {
	display: flex;
	align-items: flex-start;
	margin-bottom: 2%;
}

.IndividualCommentProfilePicture {
	min-width: 80px;
	max-width: 8%;
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	align-self: stretch;
	align-items: center;
	position: relative;
}

.IndividualCommentProfilePicture::before {
	content: '';
	display: block;
	padding-bottom: 90%;
}

.IndividualCommentProfilePicture img {
	position: absolute;
	width: 65% !important;
	aspect-ratio: 1 / 1;
	border-radius: 100px;
	object-fit: cover;
	bottom: 0;
	margin-bottom: 10%;
}

.IndividualCommentTextArea {
	width: 92%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.IndividualCommentTextArea h2 {
	font-size: medium;
	margin-bottom: -0.5%;
}

.IndividualCommentTextNotOwner {
	width: 80%;
}

.IndividualCommentTextNotOwner h4 {
	text-align: end;
}

.IndividualCommentTextOwner {
	width: 80%;
}

.IndividualCommentText {
	background-color: #F0F7FD;
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 1%;
	margin-bottom: 1%;
	height: auto;
	padding: 10px;
}

.IndividualCommentText img {
	width: 80%;
	margin-bottom: 2%;
}

.IndividualCommentText h2 {
	font-size: large;
}

.IndividualCommentText h4 {
	font-size: x-small;
	font-weight: normal;
}

.IndividualCommentText h2,
.IndividualCommentText h4 {
	margin: 0;
	padding: 0;
	width: 95%;
}

.CommentInputMainDiv {
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 6vh;
	margin-bottom: 2%;
	border-radius: 35px;
	border: solid 2px #009FFF;
}

.CommentInputSubDiv {
	width: 95%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.CommentInputSubDiv input {
	width: 70%;
	min-height: 5.5vh;
	background-color: transparent;
	border: none;
}

.CommentInputSubButtonDiv {
	width: 30%;
	display: flex;
	justify-content: right;
}

.CommentInputFileDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 80%;
	justify-content: center;
}

.CommentInputFileDiv button {
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 25px;
	border: solid 2px #009FFF;
	background-color: #009FFF;
	cursor: pointer;
	font-weight: bold;
	font-size: medium;
	color: white;
	margin-left: 5%;
}

.CommentInputFileDiv button:hover {
	background-color: white;
	color: #009FFF;
}

.CommentInputSubButtonDiv label {
	width: 15%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: solid 2px #009FFF;
	border-radius: 25px;
	background-color: white;
	cursor: pointer;
	font-weight: bold;
	font-size: medium;
	margin-left: 2%;
}

.CommentInputSubButtonDiv label img {
	width: 60%;
}

.CommentInputSubDiv button {
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 25px;
	border: solid 2px #009FFF;
	background-color: #009FFF;
	cursor: pointer;
	font-weight: bold;
	font-size: medium;
	color: white;
}

.CommentInputSubDiv button:hover {
	background-color: white;
	color: #009FFF;
}

/* We define the sizes of the CreateNoticeComponent */

.CreateNoticeMainDiv,
.CreateNewsLetterMainDiv {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.CreateNoticeSubMainDiv,
.CreateNewsLetterSubMainDiv {
	width: 95%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: left;
}

.CreateNoticeSubMainDiv h4,
.CreateNewsLetterSubMainDiv h4 {
	width: 100%;
	text-align: left;
}

.CreateNoticeSubMainDiv textarea,
.CreateNewsLetterSubMainDiv textarea {
	margin-top: -1%;
	width: calc(95% - 20px);
	resize: none;
	height: auto;
	border-radius: 10px;
	border: solid 2px #009FFF;
	background-color: #C7E1FD30;
	font-family: 'Inter', sans-serif;
	font-size: small;
	color: #009FFF;
	padding: 10px;
}

.SmallTextArea textarea {
	min-height: 25px;
}

.BigTextArea textarea {
	min-height: 100px;
}

.SmallNewsLetterTextArea textarea {
	min-height: 20px;
}

.BigNewsLetterTextArea textarea {
	min-height: 200px;
	margin-bottom: 2%;
}

.CreateNoticeSubMainDiv label {
	margin: 2%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-family: 'Inter', sans-serif;
	font-size: medium;
}

.CreateNoticeSubMainDiv button,
.CreateNewsLetterSubMainDiv button {
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 25px;
	width: 20%;
	border: solid 2px #009FFF;
	background-color: #009FFF;
	cursor: pointer;
	font-weight: bold;
	font-size: medium;
	color: white;
	margin-bottom: 2%;
}

.CreateNoticeSubMainDiv button:hover {
	background-color: white;
	color: #009FFF;
}

.CreateNoticeSelect,
.CreateNewsLetterSelect {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	width: 95%;
	appearance: auto;
	padding: 10px;
	padding-left: 40px;
	border-radius: 30px;
	border: solid 3px #009FFF;
	background-color: white;
	font-family: 'Inter', sans-serif;
	font-size: medium;
	font-weight: bold;
	color: #009FFF;
	box-sizing: border-box;
}

/* We define the sizes of the ListMyGroups */

.ListMyGroupsMainDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}


/* We define the sizes of the SummaryMyGroup */

.SummaryMyGroupMainDiv {
	margin-top: 1%;
	margin-bottom: 1%;
	border: solid 3px #009FFF;
	border-radius: 55px;
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
}

.SummaryMyGroupSubDiv {
	width: 90%;
}

.SummaryMyGroupInternalRowDiv {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.SummaryMyGroupInternalLeftDiv {
	width: 80%;
	border-radius: 100px;
}

.SummaryMyGroupInternalLeftDiv h4 {
	padding-left: 25px;
}

.SummaryMyGroupInternalRightDiv {
	width: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.SummaryMyGroupInternalRightDiv h4 {
	color: #009FFF;
	background-color: white;
	padding: 10px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 10px;
	border: solid 2px #009FFF;
}

.SummaryMyGroupInternalRowDiv h2 {
	font-weight: bold;
	color: #009FFF;
}

.SummaryMyGroupInternalRowDiv button {
	padding: 10px;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 30px;
	cursor: pointer;
	font-weight: bold;
	font-size: medium;
	border: solid 2px #009FFF;
	background-color: #009FFF;
	color: white;
}

.SummaryMyGroupInternalRowDiv button:hover {
	border: solid 2px #009FFF;
	background-color: white;
	color: #009FFF;
}

/* ListGroupUsers */

.ListGroupUsersMainDiv {
	width: 75%;
	margin-bottom: 2%;
}

.ListGroupUsersMainDiv h4 {
	color: #0045aa;
	margin-bottom: 1%;
}

.ListUserEmptyList {
	width: 100%;
	margin-bottom: 1%;
	border-radius: 10pxs;
	text-align: center;

}

.IndividualUserDiv {
	width: 100%;
	margin-bottom: 1%;
	border: solid 2px #009FFF;
	border-radius: 10px;
}

.IndividualUserDiv h3 {
	padding-left: 2.5%;
	font-weight: lighter;
	color: #0045aa;
}

/* ListGroupStudents */

.ListGroupStudentMainDiv {
	width: 75%;
	margin-bottom: 2%;
}

.ListGroupStudentMainDiv h4 {
	color: #0045aa;
	margin-bottom: 1%;
}

.IndividualStudentDiv {
	display: flex;
	align-items: center;
	width: 100%;
}

.IndividualStudentDiv h4 {
	font-weight: lighter;
}

.IndividualStudentDiv button {
	width: 40%;
	background-color: transparent;
	border: none;
	border-radius: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.IndividualStudentDiv img {
	width: 40%;
}

.IndexColumn {
	flex: 0 0 10%;
	text-align: center;
}

.NameColumn {
	flex: 0 0 80%;
	text-align: left;
}

.DeleteButton {
	flex: 0 0 10%;
	text-align: center;
}

/* ViewUserPicture */

.ViewUserMainDiv {
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ViewUserMainDataDiv {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
}

.ViewUserSubDataDiv {
	width: 47.5%;
}

.ViewUserSubDataDiv h3 {
	font-size: medium;
	font-weight: bold;
	color: #0045aa;
}

.ViewUserSubDataDiv h4 {
	font-size: medium;
	font-weight: lighter;
	color: #0045aa;
	padding: 15px;
	padding-left: 25px;
	border-radius: 30px;
	margin-top: -1%;
	border: solid 2px #0045aa;
}

.ViewUserSpecialh4 {
	background-color: #009FFF50;
}

.ViewUserGroupsDiv {
	text-align: start;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-bottom: 2%;
}

.ViewUserGroupsDiv h3 {
	text-align: start;
	font-size: medium;
	font-weight: bold;
	color: #0045aa;
}

.ViewUserGroupsSubDiv {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border: solid 2px #0045aa;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
}

.ViewUserGroupsSubDiv h4 {
	padding: 5px;
	padding-left: 25px;
	padding-right: 25px;
	margin-left: 2%;
	margin-right: 2%;
	font-weight: lighter;
	font-size: medium;
	background-color: #009FFF50;
	color: #0045aa;
	border: solid 2px #0045aa;
	border-radius: 100px;
}

/* HandleImage */

.ViewUserPicture {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.ViewUserPicture img {
	margin-top: 2%;
	margin-bottom: 1%;
	width: 16%;
	aspect-ratio: 1/1;
	border-radius: 150px;
}

.ViewUserPictureButtons {
	width: 60%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.ViewUserPicture input {
	margin-top: 10%;
	margin-bottom: 7%;
}

.ViewUserPictureButtons button {
	margin-top: 1%;
	margin-bottom: 2%;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 30px;
	width: 30%;
	cursor: pointer;
	font-weight: bold;
	font-size: medium;
	border: solid 2px #009FFF;
	background-color: #009FFF;
	color: white;
}

.ViewUserPictureButtons button:hover {
	border: solid 2px #009FFF;
	background-color: white;
	color: #009FFF;
}

/* ListUserAgendamMainDiv */

.ListUserAgendamMainDiv {
	width: 90%;
	margin-bottom: 2%;
}

.ListUserAgendamMainDiv h3 {
	font-size: medium;
	font-weight: bold;
	color: #0045aa;
}

.MapAgendaByDayDiv {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

/* ViewIndividualAgenda */

.ViewIndividualAgendaMainDiv {
	margin-top: 1%;
	margin-bottom: 1%;
	width: 30%;
	border-radius: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.ViewIndividualAgendaSuperiorDiv {
	width: 90%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: -1.5%;
}

.ViewIndividualAgendaSuperiorLeftDiv {
	width: 30%;
	text-align: left;
	color: #0045aa;
}

.OnlyDistribution {
	width: 70%;
	display: flex;
	flex-direction: row;
	justify-content: end;
	align-items: center;
}

.EvenDistribution {
	width: 70%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.ViewIndividualAgendaSuperiorDiv button {
	padding: 8px;
	border-radius: 30px;
	width: 45%;
	cursor: pointer;
	font-weight: bold;
	font-size: small;
	border: solid 2px #009FFF;
	background-color: #009FFF;
	color: white;
	text-align: center;
}

.ViewIndividualAgendaSuperiorDiv button:hover {
	border: solid 2px #009FFF;
	background-color: white;
	color: #009FFF;
}

.ViewIndividualAgendaInferiorDiv {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	border: solid 2px #0045aa;
	border-radius: 100px;
	width: 100%;
}

.ViewIndividualAgendaInferiorDiv h4 {
	font-weight: lighter;
	color: #0045aa;
}

/* SelectLaboralHour */

.SelectLaboralHourMainDiv {
	width: 50%;
	height: inherit;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.SelectLaboralHourMainDiv label {
	color: #0045aa;
	font-weight: lighter;
	margin-right: 5%;
}

.AgendaSelect {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 2px;
	align-items: center;
	text-align: center;
	border-radius: 30px;
	border: solid 3px #009FFF;
	background-color: white;
	font-family: 'Inter', sans-serif;
	font-size: small;
	font-weight: lighter;
	color: #009FFF;
	box-sizing: border-box;
}

/* EditPassword */

.EditPassWordMainDiv {
	width: 90%;
	margin-bottom: 2%;
}

.EditPasswordTopDiv {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.EditPasswordTopDiv button {
	padding: 8px;
	border-radius: 30px;
	width: 25%;
	cursor: pointer;
	font-weight: bold;
	font-size: small;
	border: solid 2px #009FFF;
	background-color: #009FFF;
	color: white;
	text-align: center;
}

.EditPasswordTopDiv button:hover {
	border: solid 2px #009FFF;
	background-color: white;
	color: #009FFF;
}

.EditPasswordTopDiv h4 {
	color: #0045aa;
	margin-bottom: 2%;
}

.EditPasswordShowPasswords {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin-bottom: 2%;
}

.IndividualPassword {
	width: 40%;
	padding: 15px;
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 100px;
	margin-bottom: 1%;
	border: solid 2px #0045aa;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-align: center;
}

.IndividualLastPassword {
	width: 50%;
	padding: 15px;
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 100px;
	border: solid 2px #0045aa;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-align: center;
}

.IndividualPassword label,
.IndividualLastPassword label {
	font-size: small;
	font-weight: lighter;
	width: 25%;
	color: #0045aa;
	;
}

.IndividualPassword input,
.IndividualLastPassword input {
	border: solid 2px #009FFF;
	background-color: transparent;
	border-radius: 100px;
	padding-left: 15px;
}

.EditPassWordButtonDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.EditPassWordButtonDiv button {
	padding: 8px;
	border-radius: 30px;
	width: 45%;
	cursor: pointer;
	font-weight: bold;
	font-size: small;
	border: solid 2px #009FFF;
	background-color: #009FFF;
	color: white;
	text-align: center;
}

.EditPassWordButtonDiv button:hover {
	border: solid 2px #009FFF;
	background-color: white;
	color: #009FFF;
}

.EditPassWordButtonDiv button:disabled:hover,
.EditPassWordButtonDiv button:disabled {
	background-color: #009FFF50;
	border: solid 2px #009FFF50 !important;
	cursor: default;
	color: white;
}

/* UploadPopUp */

.popup-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.popup-content {
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	text-align: center;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	min-width: 80%;
	width: 80%;
}

.popup-content h1 {
	color: #0045aa;
}

.popup-buttons {
	display: flex;
	justify-content: space-evenly;
}

.popup-buttons button {
	min-width: 22%;
	max-width: 22%;
	padding: 0.5%;
	margin: 1%;
	border-radius: 25px;
	cursor: pointer;
	font-size: large;
	font-weight: bold;
	border: solid 2px transparent;
	background-color: #009FFF;
	color: white;
}

.popup-buttons button:not(:disabled):hover {
	font-size: large;
	font-weight: bold;
	border: solid 2px #009FFF;
	background-color: transparent;
	color: #009FFF;
}

.popup-buttons button:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}