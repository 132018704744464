.MainModalDiv{
    background: linear-gradient(180deg, #00E0FF 0%, #156FFF 100%);
    min-width: 100.4%;
    min-height: 100.6%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.SwitchDiv{
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.InternalDiv{
    height: 90%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.InternalDiv h1 {
    font-size: xxx-large;
    color: white;
    text-align: center;
}

.InternalDiv label {
    font-size: medium;
    color: white;
    text-align: center;
}


.LoginLogoDiv {
    width: 100%;
    background-color: #156FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
}

.LoginLogoDiv img{
    width: 20%;
    aspect-ratio: 1/1
}

.LoginInputDiv{
    width: 50%;
    margin: 1%;
}

.LoginInputDiv input {
    width: 100%;
    padding: 10px;
    border-radius: 25px;
    border: white solid 2px;
    background-color: transparent;
    font-size: medium;
    color: white;
}

.LoginPassWordInput{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1%
}

.LoginPassWordInput input {
    width: 70%;
    padding: 10px;
    border-radius: 25px;
    border: white solid 2px;
    background-color: transparent;
    font-size: medium;
    color: white;
}

.LoginPassWordInput button {
    width: 25%;
    padding: 10px;
    border-radius: 25px;
    margin-right: -20px;
    border: white solid 2px;
    background-color: transparent;
    font-size: medium;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.LoginPassWordInput button:hover {
    background-color: white;
    color: #156FFF;
    border: #156FFF solid 2px;
}

.ForgetPassWordDiv {
    width: 50%;
    justify-content: end;
    display: flex;
    flex-direction: row;
}

.ForgetPassWordDiv button {
    width: 25%;
    padding: 10px;
    border-radius: 25px;
    margin-right: -20px;
    border: none;
    background-color: transparent;
    font-size: medium;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.FinalButtonDiv{ 
    margin-top: 1%;
    margin-bottom: 1%;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.FinalButtonDiv button {
    width: calc(100% + 20px);
    padding: 10px;
    border-radius: 25px;
    margin-right: -20px;
    font-size: medium;
    font-weight: bold;
    cursor: pointer;
    background-color: white;
    color: #156FFF;
    border: #156FFF solid 2px;
}

.FinalButtonDiv button:hover {
    color: white;
    border: white solid 2px;
    background-color: transparent;
}

.LostPasswordButtonDiv {
    margin-left: 20px;
    margin-top: 1%;
    margin-bottom: 1%;
    width: calc(50% + 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.LostPasswordButtonDiv button {
    width: 40%;
    padding: 10px;
    border-radius: 25px;
    font-size: medium;
    font-weight: bold;
    cursor: pointer;
    background-color: white;
    color: #156FFF;
    border: #156FFF solid 2px;
}

.LostPasswordButtonDiv button:hover {
    color: white;
    border: white solid 2px;
    background-color: transparent;
}