.LinkClassNavBar {
	display: flex;
	min-height: 7.5vh;
	height: 5%;
	width: 100%;
	background-color: white;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	border-bottom: solid 2px #009FFF;
}

.LinkClassNavBarSubDiv {
	margin-top: 0.5%;
	width: 95%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.LinkClassNavBarSubDiv input {
	width:  50%;
	border: solid 2px #009FFF;
	border-radius: 25px;
	padding: 7px;
	margin-top: 0.5vh;
	max-height: 4vh;
}

.NavBarSearchInput {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    position: relative;
}

.NavBarSearchResults {
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; 
    border-radius: 4px;
    border: solid 2px #009FFF;
	cursor: pointer;
}

.SearchResultsList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.SearchResultItem {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.MentalHealthResolvedColorCode:hover {
	background-color: #36C6DD;
}

.CoexistenceResolvedColorCode:hover{
	background-color: #1DBF91;
}

.NursingResolvedColorCode:hover{
	background-color: #E98DFC;
}

.AgretionResolvedColorCode:hover {
	background-color: #FC6749;
}

.MissingStudentResolvedColorCode:hover {
	background-color: #F4CA45;
}

.HygienicResolvedColorCode:hover {
	background-color: #A080F9;
}

.TecnicResolvedColorCode:hover {
	background-color: #B4CC05;
}

.GeneralResolvedColorCode:hover {
	background-color: #C7E1FD;
}

.FirstSpecialResolvedColorCode:hover {
	background-color: #D18654;
}

.SecondSpecialResolvedColorCode:hover {
	background-color: #3E9367;
}

.ThirdSpecialResolvedColorCode:hover {
	background-color: #6D6DE2;
}

.FourthSpecialResolvedColorCode:hover {
	background-color: #EA4B8C;
}


.NavBarLoggedDiv {
	display: flex;
	flex-direction: row;
	width: 25%;
	justify-content: space-around;
}

.NavBarLoggedRightDiv {
	cursor: pointer;
	width: 80%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.NavBarLoggedRightDiv img{
	margin-right: 5%;
	border-radius: 500px;
	border: solid 2px #009FFF;
	max-height: 4vh;
	max-width: 4vh;
}

.NavBarLoggedDiv button {
	border-radius: 25px;
	cursor: pointer;
	font-size: small;
	font-weight: bold;
	border: solid 2px #009FFF;
	background-color: transparent;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.NavBarLoggedDiv button img {
	max-width: 3vh;
	border-radius: 50px;
	aspect-ratio: 1 / 1;
}