/* This file contains CSS to all the elements of the LandingPage View*/

/* We start with the page it self*/
.landing-page {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 100.5vh;
    min-width: 100.3%;
    overflow-x: hidden;
}

.landing-page h1 {
    font-family: 'Inter', sans-serif;
    font-size: xx-large;
    font-weight: bolder;
}

.landing-page h3 {
    font-family: 'Inter', sans-serif;
    font-size: large;
}

.landing-page button {
    border-radius: 25px !important;
    min-height: 10px;
}

/* Now we define the CSS of the Landing NavBar*/

.landing-navbar {
    background: linear-gradient(180deg, #00E0FF 0%, #156FFF 100%);
    top: 0;
    z-index: 20;
    width: 100%;
    height: max(15%, 12vh);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

}

/* Now we define the CSS of the NavBarLogo File*/

.navbar-logo {
    width: 15%;
    cursor: pointer;
    margin-left: 3%;
    justify-content: center;
    display: flex;
}

.navbar-logo img {
    max-width: 120px;
    min-width: 120px;
    aspect-ratio: 2/2;
}

/* Now we define the CSS of the Nav*/

.nav-links {
    width: 82%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.landing-basic-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.landing-basic-buttons button {
    background-color: transparent;
    border: none;
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: large;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.landing-basic-buttons button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.nav-links-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
}

.nav-links-buttons button {
    max-width: 200px;
    width: 35%;
    margin-top: 1%;
    margin-bottom: 1%;
    min-height: 3vh;
    height: 100%;
    font-family: 'Inter', sans-serif;
    font-size: large;
    font-weight: bold;
    border: 2px solid white;
    color: white;
    background-color: transparent;
    border-radius: 16px;
    cursor: pointer;
}

.nav-links-buttons button:hover {
    background-color: white;
    color: #156FFF;
    border: 2px solid #156FFF;
}

.contact-button {
    max-width: 300px !important;
    width: 45% !important;
    color: #156FFF !important;
    background-color: yellow !important;
}

.contact-button:hover {
    color: #156FFF !important;
    background-color: white !important;

}

/* Now we define the CSS of the ContactSection*/

.contact-section {
    min-height: 1000px;
    width: 100%;
    background-color: #156FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    color: white;
}

.contact-section h1 {
    width: 80%;
    color: white;
    font-size: xxx-large;
    font-weight: bolder;
    text-align: center;
}

.contact-section h2 {
    width: 80%;
    color: white;
    font-size: xx-large;
    font-weight: bolder;
    text-align: center;
}

.contact-section h3 {
    margin-top: -0.5%;
    width: 86%;
    font-size: x-large;
    font-weight: bolder;
    text-align: center;
}

.contact-form {
    width: 80%;
    background-color: white;
    height: 650px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.contact-form label {
    text-align: left;
    font-size: large;
    font-weight: bolder;
    color: #0045AA;
}

.contact-form strong {
    font-size: large;
    font-weight: bolder;
    color: red;
}

.contact-form input {
    width: calc(100% - 20px);
    border: solid 3px #0045AA;
    border-radius: 35px;
    color: black;
    padding-left: 20px;
}

.form-top-side {
    margin-top: 1%;
    width: 95%;
    height: 65%;
    display: flex;
    justify-content: space-evenly;
}

.form-split-side {
    width: 45%;
}

.form-split-side label {
    margin-top: 1%;
}

.form-split-side input {
    margin-top: 1.5%;
    height: 45%;
    font-family: 'Inter', sans-serif;
    font-size: medium;
}

.input-block {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
}

.input-block-phone {
    margin-top: 5px;
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
}

.form-message-div {
    margin-top: -1%;
    margin-bottom: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 20%;
}

.form-message-div textarea {
    margin-top: 0.5%;
    width: calc(93% - 60px);
    height: 130px;
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border: solid 3px #0045AA;
    border-radius: 35px;
    color: black;
    resize: none;
    font-family: 'Inter', sans-serif;
    font-size: medium;
}

.form-button-div {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-button-div button {
    font-family: 'Inter', sans-serif;
    font-size: large;
    font-weight: bold;
    width: 15%;
    height: 75%;
    border-radius: 25px;
    color: white;
    background-color: #156FFF;
    cursor: pointer;
}

.form-button-div button:hover {
    border: solid 3px #156FFF;
    color: #156FFF;
    background-color: white;
}

/* Now we define the CSS of the FAQ section*/

.faq-section {
    min-height: 300px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
}

.main-container {
    width: 80%;
    height: 100%;
    margin-top: 3%;
    margin-bottom: 3%;
}

.main-container h1 {
    color: #156FFF;
    font-size: xxx-large;
    font-weight: bolder;
    text-align: left;
}

.faq-item-first {
    width: 100%;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.faq-item {
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq-question-open {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq-question-open h2 {
    font-size: xx-large;
    color: #156FFF;
}

.faq-question-open button {
    width: 2%;
    height: 10%;
    background-color: transparent;
    border: none;
    color: #156FFF;
    font-size: x-large;
}

.faq-question h2 {
    font-size: xx-large;
    color: gray;
}

.faq-question button {
    width: 2%;
    height: 10%;
    background-color: transparent;
    border: none;
    color: #156FFF;
    font-size: x-large;
}

.faq-answer {
    width: 95%;
    margin-left: 2%;
    overflow: hidden;
    font-size: large;
    color: #555;
}

/* Now, we define the CSS for Phones */

@media (max-width: 768px) {

    /* Nav */

    .landing-navbar{
        height: 60px !important;
        width: 100% !important  ;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .landing-basic-buttons button{
        font-size: small;
    }

    .navbar-logo {
        margin-left: -1%;
        margin-top: -2%;
        height: 60px !important;
        width: 76px !important;
    }

    .nav-links-buttons {
        width: 50%;
    }

    .nav-links-buttons button {
        min-width: none !important;
        width: 40% !important;
        max-width: 100px;
        padding: 0;
        font-size: smaller;
        font-weight: lighter;
        overflow-x: auto;
        border: 3px solid white;
        background-color: transparent;
        color: white;
    }

    .nav-links-buttons button:hover {
        background-color: white;
        color: #156FFF;
        border: 3px solid #156FFF;
    }

    .contact-button {
        width: 45%;
        color: #156FFF !important;
        background-color: yellow !important;
    }

    .contact-button:hover {
        color: #156FFF !important;
        background-color: white !important;
    }

    /* Contact File */


    .contact-form {
        height: 600px;
    }

    .contact-section h1{
        font-size: xx-large;
    }

    .contact-section h2{
        font-size: x-large;
        text-align: center;
    }

    .contact-section h3{
        font-size: large;
    }

    .form-top-side {
        flex-direction: column;
        justify-content: space-around;
    }

    .form-split-side {
        height: 50%;
    }

    .form-top-side label{
        font-size: medium;
    }

    .form-split-side {
        width: 100%;
    }

    .input-block input{
        width: 90%;
        height: 30px;
    }

    .input-block-phone input{
        width: 90%;
        height: 28px;
    }

    .form-message-div{
        width: 100%;
    }

    .form-message-div label{
        text-align: left;
    }

    .form-button-div button{
        width: 40%;
        height: 50%;
        font-size: medium;
        font-weight: normal;
    }

    /* FAQ File */

    .main-container h1{
        font-size: x-large;
    }

    .main-container h2{
        font-size: large;
    }

    .main-container h4{
        font-size: small;
    }

    .main-container button {
        font-size: medium;
        margin-bottom: -5%;
    }

    /* LandingFooter File */

    .landing-footer {
        width: 100%;
        min-height: 0px;
    }

    .logo {
        width: 0px;
        height: 0px;
        padding-left: 0;
    }

    .logo img{
        width: 0px;
        height: 0px;
        padding-left: 0;
    }

    .footer-text {
        font-size: small;
        width: 50%;
        overflow-x: hidden;
        flex-direction: row;
    }

    .footer-text h4 {
        font-size: small;
        width: 50%;
    }
}