/* ViewSchoolAdminExtraButtons */

.ExtraButtonsMainDiv {
    margin-top: 2%;
    margin-bottom: 2%;
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ExtraButtonsSingularDiv {
    width: 30%;
    max-width: 323px;
    background-color: white;
    border: solid 2px white;
    aspect-ratio: 1/0.5;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #009FFF;
    cursor: pointer;
}

.ExtraButtonsSingularDiv:hover {
    border: solid 2px #009FFF;
}

/* MapEmergencies and MapTemplates */

.MapEmergenciesMainDiv,
.MapTemplateMainDiv {
    width: 90%;
    margin-bottom: 2%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CreateEmergencieButton,
.CreateTemplateButton {
    width: 30%;
    cursor: pointer;
    padding: 10px;
    margin-top: 1%;
    border-radius: 50px;
    border: solid 2px #009FFF;
    background-color: #009FFF;
    font-size: large;
    font-weight: bold;
    color: white;
}

.CreateEmergencieButton:hover,
.CreateTemplateButton:hover {
    border: solid 2px #009FFF;
    background-color: white;
    color: #009FFF;
}

.IndividualEmergencyDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
    margin: 0.5%;
    padding-left: 20%;
}

.IndividualEmergencyDiv input {
    margin-top: 1.75%;
    margin-bottom: 1.75%;
    width: 85%;
    padding: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    background-color: transparent;
    border: solid 3px #009FFF;
    border-radius: 5px;
    font-size: medium;
    font-weight: bold;
}

.IndividualEmergencyDiv button,
.IndividualTemplateDiv button {
    margin-left: 2%;
    width: 6%;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.IndividualEmergencyDiv button img,
.IndividualTemplateDiv button img{
    width: 100%;
}

.IndividualEmergencyInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    border: solid 2px #009FFF;
    border-radius: 50px;
    padding-left: 40px;
    padding-right: 40px;
}


.IndividualEmergencyInfo h3 {
    width: 10%;
    font-size: medium;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.IndividualEmergencyInfo h4 {
    width: 50%;
    font-size: medium;
}

.IndividualTemplateDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
    margin: 0.5%;
    padding-left: 20%;

}

.IndividualTemplateInfoMainDiv {
    width: 100%;
    margin-left: -15%;
    display: flex;
    flex-direction: column;
    border: solid 2px #009FFF;
    border-radius: 10px;
}

.IndividualTemplateInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.IndividualTemplateInfoContainer h3 {
    width: 10%;
    font-size: medium;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    color: #009FFF;
}

.IndividualTemplateInfoRowContainer {
    width: 80%;
}

.IndividualTemplateInfo h4 {
    width: 40%;
    font-size: medium;
}
.IndividualTemplatePriorityRow, .IndividualTemplatePriorityFirstRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: #009FFF;
}

.IndividualTemplatePriorityFirstRow input {
    margin-top: 1.75%;
    margin-bottom: 1.75%;
    width: 85%;
    padding: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    background-color: transparent;
    border: solid 3px #009FFF;
    border-radius: 5px;
    font-size: medium;
    font-weight: bold;
    color: #009FFF;
}

.IndividualTemplatePriorityFirstRow h4 {
    width: 70%;
    margin-left: 2%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 25px;
    text-align: center;
    font-weight: bold;
    font-size: large;
    color: black;
    text-align: start;
}


.IndividualTemplatePriorityFirstRow button {
    background-color: transparent;
    border: none;
    width: 30%;
    font-size: large;
    font-weight: bolder;
    color: #009FFF;
    cursor: pointer;
}

.IndividualTemplatePriorityRow h4 {
    width: 30%;
    margin-left: 2%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 25px;
    text-align: center;
    font-weight: lighter;
    color: black;
}

.BlueColorText h4 {
    color: #009FFF !important;
}

.PriorityButtons {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.PriorityButtons button {
    width: 22%;
    text-align: center;
    font-size: small;
    padding: 5px;
    border: solid 1px #009FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    margin-top: 1%;
    margin-bottom: 1%;
    font-weight: bold;
    cursor: pointer;
}

.SelectedPrio {
    background-color: #009FFF !important;
    color: white;
}

.UnSelectedPrio {
    background-color: white;
    color: #009FFF;
}

/* ListStudentAdmin */

.SearchStudentDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.SearchStudentDiv button {
    width: 20%;
    border: solid 2px #009FFF;
    background-color: #009FFF;
    color: white;
    border-radius: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1%;
    margin-bottom: 1%;
    font-weight: bold;
    font-size: medium;
    cursor: pointer;
}

.SearchStudentDiv button:hover {
    background-color: white;
    color: #009FFF;
}

.AddStudentSearch {
    width: 60%;
    padding: 15px;
    border-radius: 30px;
    border: 2px solid #009FFF;
    position: relative;
    font-size: medium;
}

.StudentSearchResults {
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 4px;
    border: solid 2px #009FFF;
    cursor: pointer;
}

.StudentSearchList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.StudentSearchItem {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.StudentSearchItem:hover {
    background-color: #009FFF;
    color: white;
}


/* ListGroupUsersAdmin */

.IndividualUserMainDiv {
    margin-left: 5%;
    width: 87.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1%;
}

.AddUserSearch {
    width: 85%;
    padding: 15px;
    border-radius: 30px;
    border: 2px solid #009FFF;
    position: relative;
    font-size: medium;
}

.IndividualUserMainDiv button {
    width: 8%;
    margin-left: 2%;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.IndividualUserMainDiv button img {
    width: 100%;
}

.IndividualUserInternalMainSubDiv {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 2px #009FFF;
    border-radius: 10px;
}

.IndividualUserInternalDiv {
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.IndividualUserInternalDiv h3 {
    width: 70%;
    padding-left: 2.5%;
    font-weight: lighter;
    color: #0045aa;
}

.IndividualUserInternalDiv button {
    width: 30%;
    background-color: transparent;
    border: none;
    font-size: large;
    font-weight: bold;
    color: #009FFF;
    cursor: pointer;
    text-align: center;
}

.UserTemplateMainDiv {
    width: 80%;
}

.UserTemplatePriorityRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: #009FFF;
}

.UserTemplatePriorityRow h1 {
    color: transparent;
    width: 10%;
}

.UserTemplatePriorityRow h4 {
    width: 40%;
    margin-left: 2%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 25px;
    text-align: center;
    font-weight: lighter;
    color: black;
    margin-top: 2%;
    margin-bottom: 2%;
}

.PriorityButtonsUser {
    width: 60%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.PriorityButtonsUser button {
    width: 22%;
    text-align: center;
    font-size: small;
    padding: 5px;
    border: solid 1px #009FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    margin-top: 1%;
    margin-bottom: 1%;
    font-weight: bold;
    cursor: pointer;
}

.BlueColorText h4 {
    color: #009FFF !important;
}

.UserSelectTemplate {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.SelectTemplateForUser {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    text-align: center;
    margin-bottom: 2%;
    border-radius: 30px;
    border: solid 2px #009FFF;
    background-color: white;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    font-weight: lighter;
    color: #009FFF;
    box-sizing: border-box;
}

/* MapGroup */

.EditGroupNameInput {
    width: 60%;
    padding: 5px;
    padding-left: 10px;
    border-radius: 30px;
    border: 2px solid #009FFF;
    position: relative;
    font-size: medium;
    color: #0045aa;
}

/* ListAllUsers */

.AllUsersTable {
    width: 100%;
    border-collapse: collapse;
}

.AllUsersTable thead {
    border-bottom: solid 2px #009FFF;
    color: #009FFF !important;
    font-size: large;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
}

.AllUsersTable thead tr {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.AllUsersTable thead tr th {
    margin-bottom: 0.5%;
}

.AllUsersTable th:nth-child(1),
.AllUsersTable td:nth-child(1) {
    width: 10%;
    text-align: center;
    justify-content: space-evenly;
}

.AllUsersTable th:nth-child(4),
.AllUsersTable td:nth-child(4),
.AllUsersTable th:nth-child(5),
.AllUsersTable td:nth-child(5) {
    width: 8%;
    text-align: center;
    justify-content: space-evenly;
}

.AllUsersTable th:nth-child(2),
.AllUsersTable td:nth-child(2),
.AllUsersTable th:nth-child(3),
.AllUsersTable td:nth-child(3) {
    width: 37%;
    text-align: start;
    justify-content: space-evenly;
}

.AllUsersTable tbody tr {
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    display: flex;
    align-items: center;
    color: #0045aa;
}

.AllUsersTable th:nth-child(4) button,
.AllUsersTable td:nth-child(4) button {
    background-color: #009FFF;
    color: white;
    border: solid #009FFF 2px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
}

.AllUsersTable th:nth-child(4) button:hover,
.AllUsersTable td:nth-child(4) button:hover {
    background-color: white;
    color: #009FFF;
}

.AllUsersTable td:nth-child(5) button {
    background-color: transparent;
    border: none;
    width: 90%;
    cursor: pointer;
}

.AllUsersTable td:nth-child(5) button img {
    width: 40%;
}

/* ListAllStudents */

.AllStudentsTable {
    width: 100%;
    border-collapse: collapse;
}

.AllStudentsTable thead {
    border-bottom: solid 2px #009FFF;
    color: #009FFF !important;
    font-size: large;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
}

.AllStudentsTable thead tr {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.AllStudentsTable thead tr th {
    margin-bottom: 0.5%;
}


.AllStudentsTable th:nth-child(1),
.AllStudentsTable td:nth-child(1) {
    width: 10%;
    text-align: center;
    justify-content: space-evenly;
}

.AllStudentsTable th:nth-child(2),
.AllStudentsTable td:nth-child(2) {
    width: 75%;
    text-align: start;
    justify-content: space-evenly;
}

.AllStudentsTable th:nth-child(3),
.AllStudentsTable td:nth-child(3) {
    width: 15%;
    text-align: start;
    justify-content: space-evenly;
}


.AllStudentsTable tbody tr {
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    display: flex;
    align-items: center;
    color: #0045aa;
}

.AllStudentsTable th:nth-child(3) button,
.AllStudentsTable td:nth-child(3) button {
    background-color: #009FFF;
    color: white;
    border: solid #009FFF 2px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
}

.AllStudentsTable th:nth-child(3) button:hover,
.AllStudentsTable td:nth-child(3) button:hover {
    background-color: white;
    color: #009FFF;
}

/* SearchGroupAdmin */

.AddGroupSearch {
    width: calc(100% - 17px);
    padding: 15px;
    border-radius: 30px;
    border: 2px solid #009FFF;
    position: relative;
    font-size: medium;
    color: #0045aa;
    margin-top: -0.5%;
}

.GroupSearchResults {
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 4px;
    border: solid 2px #009FFF;
    cursor: pointer;
}

.GroupSearchList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.GroupSearchItem {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.GroupSearchItem:hover {
    background-color: #009FFF;
    color: white;
}

/* CreateStudentPopUp */

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 300px;
}

.popup-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.popup-buttons button {
    padding: 10px 20px;
    cursor: pointer;
}